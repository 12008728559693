<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">NEW OFFICERS/COMMITTEE</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              md="12"
              cols="12"
              class="mt-4"
            >
              <v-combobox
                class="mx-2"
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="search"
                @keyup="searching($event.target.value)"
                @change="get_search_items_info"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Search by (Lastname/Firstname/CMF #)"
                ref="memberCombobox"
                dense
                outlined
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="effective_date"
                dense
                outlined
                label="Effective Date"
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="position_id"
                dense
                outlined
                label="Positions"
                item-value="id"
                item-text="position"
                :items="positions_items"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-checkbox
                v-model="is_chairperson"
                label="Is Chairperson"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <OrganizationalChart
        />
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import OrganizationalChart from '@/views/admin_interface/OrganizationalChart'

  export default {
    components: {
      snackBarDialog,
      OrganizationalChart,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        effective_date: '',
        is_chairperson: false,

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],
        positions_items: [],

        position_id: '',
        member_id: '',
      }
    },
    created() {
      this.list_of_available_position()
        .then(response => {
          this.positions_items = response.data
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_user', 'is_user_already_encoded']),
      ...mapActions('members_information', ['search_members_paid']),
      ...mapActions('organization_position', ['list_of_available_position']),
      ...mapActions('organization_history', ['register_officer_committee']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.effective_date = ''
        this.member_id = ''
        this.search = ''
        this.search_member = ''
        this.search_items = []
        this.position_id = ''
        this.is_chairperson = false
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.member_to_loan = {}
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      get_search_items_info() {
        this.member_id = ''
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.member_id = this.search_items[index].id
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('member_id', this.member_id);
          data.append('position_id', this.position_id);
          data.append('effective_date', this.effective_date);
          data.append('is_chairperson', this.is_chairperson ? 1 : 0);
          this.register_officer_committee(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
